<template>
  <v-container fluid>
    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between " >
          <div class="d-flex bordered qp-tab-nav">
            <div class="nv_item">
              <v-autocomplete
                  :items="[]"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"
                  disabled
              >
              </v-autocomplete>
            </div>
            <MembershipTopTab :dashboardClass="false" :scheduleClass="false" :scheduleNewClass="true" :analyticsClass="false" :membersClass="false"/>
          </div>
        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>
    <div class="md-card md-theme-default mt-8 shadow rounded-5 membership-schedule-new">
        <div class="md-card-content">
            <v-row>
              <v-col sm="11" class="align-center">
                <div class="calender-navi">
                  <v-row no-gutters>
                    <v-col sm="1" class="text-lg-center mr-1">
                      <v-btn
                        fab
                        x-small
                        color="white"
                        @click="prevDate"
                      >
                        <v-icon dark>mdi-menu-left</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col sm="8" class="text-lg-center">
                      <date-field
                        v-model="date"
                        :buttonAndText="true"
                        :dayName="true"
                        :back-fill="true"
                        @change="changeDate"
                      >
                      </date-field>
                    </v-col>
                    <v-col sm="1" class="text-lg-center ml-1">
                      <v-btn
                        fab
                        color="white"
                        x-small
                        @click="nextDate"
                      >
                        <v-icon dark>mdi-menu-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-spacer></v-spacer> 
              <v-col sm="1">
                <div class="mx-2">
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      style="height: 40px; border: 1px solid #dcdcdc !important"
                      :elevation="hover ? 2 : 0"
                      :color="hover ? 'teal' : 'white'"
                      @click="getMembersLog"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </v-hover>
                </div>
              </v-col>
            </v-row>
            <div style="display: inline; display: flex" class="mt-6">
                <div style="width: 164px" class="d-flex flex-column overflow-x-hidden overflow-y-hidden">
                  <MemberAttendanceHeaderCell  name="Time" />
                  <div
                    class="overflow-auto no-scroll"
                    ref="scheduleTimer"
                    @scroll="onScroll"
                    :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px;margin-right:-1px;`"
                  >
                    <MemberAttendacnceTimeColumn :increment="increment" :height="height" startTime="12:00 am" endTime="11:59 pm" />
                  </div>
                </div>
                <div style="width: calc(100% - 200px)">
                  <div  
                    class="d-flex overflow-x-hidden overflow-y-hidden"
                    ref="scheduleHeader" v-if="this.logs">
                    <template v-for="(weekday, index) in weekDays">
                      <MemberAttendanceHeaderCell 
                        :key="`h_${index}`"
                        :increment="increment"
                        :height="height"
                        :name="getFormattedDate(weekday.date)"
                        :date="weekday.date"
                        :totalAttendees="getTotalCount(weekday.date)"
                        :perDayCapacity="1000"
                      />
                    </template>
                  </div>
               
                  <div
                      v-resize="onResize"
                      ref="schedule"
                      class="d-flex overflow-auto grey--text text--lighten-2 caption"
                      :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px`"
                      @scroll="onScroll"
                    >
                      <template v-for="(log, dateInex) in logs">
                        <MemberAttendanceDataColumn
                          :key="`t_${dateInex}`"
                          :increment="increment"
                          :height="height"
                          :timeslots="log"
                          :date="dateInex"
                          @openParticipantPopup="openParticipantPopup"
                        />
                      </template>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <MembersCheckInCheckoutPopup :mpopup="mpopup" :selectedData="selectedData"  @close="closeModal" @showUserModal="showUserModal" />
    <customer-model
        v-bind="userModel"
        @close="userModel.userID = null"
        :isPermissionCheck="false"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import MembershipTopTab from "./MembershipTabs/MembershipTopTab";
import MemberAttendanceHeaderCell from "@/components/Membership/MemberAttendanceHeaderCell.vue";
import MemberAttendanceDataColumn from "@/components/Membership/MemberAttendanceDataColumn.vue";
import MemberAttendacnceTimeColumn from "@/components/Membership/MemberAttendacnceTimeColumn.vue";
import MembersCheckInCheckoutPopup from "@/components/Membership/MembersCheckInCheckoutPopup.vue";
import CustomerModel from "@/views/Clients/Customer/CustomerModel.vue";
export default {
  components: {
    MembershipTopTab,MemberAttendanceHeaderCell,MemberAttendacnceTimeColumn,
    MemberAttendanceDataColumn,MembersCheckInCheckoutPopup,CustomerModel
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      weekDays: [],
      increment: 60,
      height: 1200,
      scheduleHeight: 400,
      mpopup:false,
      selectedDate: moment().format("YYYY-MM-DD"),
      selectedTime: "12:00 am - 01-00 am",
      logs: [],
      timeSlots: [],
      selectedData:{},
      userModel: {userID: null, type: "details"},
    };
  },
  mounted() {
    this.generateWeekDays();
    this.getMembersLog();
    this.getTimeSlots();
  },

  computed: {
  },
  watch: {
  },
  methods: {
    gotoPage(route){
      this.$router.push(route);
    },
    nextDate() {
      let amount = 7;
      this.date = moment(this.date).add(amount, "days").format("YYYY-MM-DD");
      this.generateWeekDays();
      this.getMembersLog();
    },
    prevDate() {
      let amount = 7;
      this.date = moment(this.date).subtract(amount, "days").format("YYYY-MM-DD");
      this.generateWeekDays();
      this.getMembersLog();
    },
    changeDate(){
      this.generateWeekDays();
      this.getMembersLog();
    },
    getFormattedDate(date) {
      return moment(date, "YYYY-MM-DD").format("ddd Do");
    },
    generateWeek(startingDate) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const result = [];

      // Convert the starting date string to a Date object
      const startDate = new Date(startingDate);

      // Generate the list of weekdays starting from the given date
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);
        const day = daysOfWeek[currentDate.getDay()];
        result.push({
          day: day,
          date: currentDate.toISOString().split("T")[0],
        });
      }
      return result;
    },
    generateWeekDays() {
      this.weekDays = [];
      this.weekDays = this.generateWeek(this.date);
    },
    getMembersLog(){
      this.showLoader("Loading");
      this.$http
        .get("venues/memberships/attendance?from_date="+this.date+'&interval='+this.increment)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.logs = response.data.data;
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getTotalCount(date){
      let checkIn = 0;
      let checkOut = 0;
      if(date && Object.keys(this.logs).length){
        this.logs[date] && Object.keys(this.logs[date]).forEach((key) => {
          const item = this.logs[date][key];
          checkIn += item.total_check_in;
          checkOut += item.total_check_out;
        })
      }
      return { check_in: checkIn, check_out: checkOut };
    },
    onResize() {
      this.scheduleHeight = window.innerHeight - 350;
    },
    onScroll() {
      const refElement = this.$refs.schedule;
      if (refElement) {
        const scrollLeft = refElement.scrollLeft;
        const scrollTop = refElement.scrollTop;
        this.$refs.scheduleTimer.scrollTop = scrollTop;
        this.$refs.scheduleHeader.scrollLeft = scrollLeft;
      }
    },
    openParticipantPopup(data){
      this.mpopup = true;
      this.selectedData.date = data.date;
      this.selectedData.hour = data.hour;
      this.selectedData.slots = data.slots;
      if(this.timeSlots && this.timeSlots.length > 0){
        this.selectedData.time = this.timeSlots.find(slot => slot.hour === data.hour).time;
      }
    },
    closeModal(){
      this.userModel.userID = null;
      this.mpopup = false;
      this.userModel.userID = null
    },
    getTimeSlots() {
      let timeSlots = [];
      let start = new Date();
      start.setHours(0, 0, 0, 0); // Set time to midnight

      // Loop through 24 hours with 1-hour intervals
      for (let i = 0; i < 24; i++) {
          // Get the current hour
          let hour = start.getHours();

          // Store the time slot
          let startT = new Date(start);
          let endT = new Date(startT.getTime() + (60 * 60 * 1000));

          timeSlots.push({
              hour: hour,
              time: startT.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })+' - '+endT.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) , // Format start time
          });

          // Move to the next hour
          start.setHours(start.getHours() + 1);
      }
      this.timeSlots = timeSlots;
    },
    showUserModal(userId) {
      this.userModel.userID = userId;
      this.userModel.type = "details";
    },

  }
};
</script>
