<template>
    <v-sheet
      color="#E9F1F6"
      dark
      width="180"
      height="70px"
      class="title flex-grow-0 flex-shrink-0 text-center pb-1 membership-header-cell"
      style="position: relative; color:black; font-weight: 600"
    >
      <div :class="`header_name ${name}`" :title="name">
        {{ name }}
      </div>
      <div class="c-booking-btn" v-if="name != 'Time'">
        <span
          color="black"
          x-small
          dark
          outlined
          >Total CI: {{ totalAttendees.check_in }}
      </span>
      <span
          color="black"
          x-small
          dark
          outlined
          >CO: {{ totalAttendees.check_out }}
      </span>
    </div>
  </v-sheet>
</template>
<script>
  export default {
    props: {
      name: { type: String, default: "Pitch Name" },
      totalAttendees: { type: Object, default: null },
      isEnablePerDayCapacity: { type: Number, default: 0 },
    },
  };
</script>