<template>
<div>
    <v-dialog v-model="mpopup" max-width="860px" @input="$emit('close')" persistent>
        <v-card class="member-checkin-checkout-popup">
            <v-card-text class="pa-0">
                <div class="row pt-1 m-0 border-bottom">
                    <div class="col-md-11">
                        <span class="header" v-if="selectedData">{{ selectedData.date | dayDateFormat}} <br> {{ selectedData.time }}</span>
                    </div>
                    <div class="col-md-1">
                        <div class="close-icon-btn">
                            <v-btn  fab x-small class="shadow-0" @click="closeModal">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <v-row class="row m-0"> 
                    <v-col cols="6"  md="6" class="p-0"><div class="pop-heading checkin">Check In ({{ selectedData.slots?selectedData.slots.total_check_in:'' }})</div></v-col>
                    <v-col cols="6"  md="6" class="p-0"><div class="pop-heading checkout">Check Out ({{ selectedData.slots?selectedData.slots.total_check_out:'' }})</div></v-col>
                </v-row>
                <v-row class="ma-0 members-area">
                    <v-col cols="6"  md="6" class="p-0">
                        <div class="members-list checkin" v-for="(member, index) in logs.checkin" :key="`mci_${index}`" @click="showUserModal(member.id)">
                            <div class="member-img">
                                <v-avatar>
                                    <view-image :image="member.profile_image"></view-image>
                                </v-avatar>
                            </div>
                            <div class="member-content">
                                <div class="name">{{ member.first_name }} {{ member.last_name }}</div>
                                <div class="time">{{ member.check_in | timeStampOrignal }}</div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6"  md="6" class="p-0">
                        <div class="members-list checkout" v-for="(member, index) in logs.checkout" :key="`mco_${index}`" @click="showUserModal(member.id)">
                            <div class="member-img">
                                <v-avatar>
                                    <view-image :image="member.profile_image"></view-image>
                                </v-avatar>
                            </div>
                            <div class="member-content">
                                <div class="name">{{ member.first_name }} {{ member.last_name }}</div>
                                <div class="time">{{ member.check_out | timeStampOrignal }}</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
export default {
    props: {
        mpopup: { type: Boolean, default: false },
        selectedData: { type: Object, default: null },
    },
    data() {
        return {
            logs: [],
        };
    },
    watch: {
        mpopup(val) {
            if (!val) {
                this.logs = [];
            }
            else {
                this.getLog();
            }
        },
    },
    methods:{
        getLog() {
            this.showLoader("Loading...");
            this.$http.get(`venues/memberships/attendance-slot-member?date=${this.selectedData.date}&hour=${this.selectedData.hour}`).then((response) => {
                if (response.status == 200 && response.data.status) {
                    this.hideLoader();
                    this.logs = response.data.data;
                }else{
                    this.logs = [];
                }
            }).catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
        },
        getImage(image){
            return this.s3BucketURL+image;
        },
        showUserModal(userId) {
            this.$emit('showUserModal',userId);
        },
        closeModal() {
            this.$emit("close");
        },
    }
}
</script>