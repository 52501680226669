<template>
  <v-hover v-slot="{ hover }">
    <v-sheet
      block
      :height="height"
      :class="`d-flex justify-center align-center cursorstyle ms-border-bottom  membership-schedule-cell ${hover?'hoverstyle' : ''}`"
    >
      <v-row>
          <v-col md="12" :sm="12" class="pr-8" @click="onClick">
              <div class="checkin-checkout-div">
                  <div class="cc-row">
                      <span class="checkin">Check In</span>
                      <span class="checkin-count">{{ tslot.total_check_in?tslot.total_check_in:'--' }}</span>
                  </div>
                  <div class="cc-row">
                      <span class="checkout">Check Out</span>
                      <span class="checkout-count">{{ tslot.total_check_out?tslot.total_check_out:'--' }}</span>
                  </div>
              </div>
          </v-col>
      </v-row>
    </v-sheet>
  </v-hover>
</template>
<script>
export default {
  props: {
    height: { type: Number, default: 50 },
    index: { type: Number, default: 0 },
    tslot: { type: Object, default: null}
  },
  methods: {
    onClick() {
      if (this.tslot.total_check_in > 0 || this.tslot.total_check_out > 0) {
        this.$emit("onClickSlot",{hour:this.index,slots: this.tslot});
      }else{
        this.showError("No Data Found");
      }
    },
  },
}
</script>