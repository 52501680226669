<template>
  <v-card
    color="grey"
    :height="height"
    width="180"
    tile
    class="flex-grow-0 flex-shrink-0"
  >
    <template v-for="slot in timeData">
      <v-sheet
        block
        :height="slot.height"
        :class="`d-flex justify-center align-center time_${slot.id}`"
        style="border-right: 1px solid rgba(0, 0, 0, 0.05);"
        :key="slot.id"
      >
        {{ slot.start_time }} - {{ slot.end_time }}
      </v-sheet>
      <!-- <v-divider :key="`d_${slot.id}`"></v-divider> -->
    </template>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    increment: { type: Number, default: 60 },
    height: { type: Number, default: 1000 },
    startTime: { type: String, default: "8:00 am"},
    endTime: { type: String, default: "10:00 pm"},
  },
  data() {
    return {
      timeData: [],
    };
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "HIGHLIGHT") {
        let element = this.$el.getElementsByClassName(
          `time_${mutation.payload}`
        )[0];
        if (element) {
          element.classList.add("highlight");
        }
      } else if (mutation.type === "REMOVE_HIGHLIGHT") {
        let element = this.$el.getElementsByClassName(
          `time_${mutation.payload}`
        )[0];
        if (element) {
          element.classList.remove("highlight");
        }
      }
    });
  },
  watch: {
    height: {
      immediate: true,
      handler() {
        this.getSlotData();
      },
    },
  },
  methods: {
    getSlotData() {
      const totalDayMinutes = 1440;
      const divisions = totalDayMinutes / this.increment;
      const cellHeight = parseInt(this.height / divisions);
      const startTimeMoment = moment(this.startTime, "hh:mm a");
      const endTimeMoment = moment(this.endTime, "hh:mm a");
      let data = [];
      for (let index = 0; index < totalDayMinutes; index += this.increment) {
        let start = moment().startOf("day").add(index, "minutes");
        let end = start.clone().add(this.increment, "minutes");
        if(end.format('hh:mm a') == "12:00 am"){
          end = start.clone().add(this.increment - 1, "minutes");
        }
        
        
        if (start.isSameOrAfter(startTimeMoment) && end.isSameOrBefore(endTimeMoment)) {
        const element = {
            id: index,
            start_time: start.format("hh:mm a"),
            end_time: end.format("hh:mm a"),
            height: cellHeight,
            fixed: true,
        };
        data.push(element);
        }
    }
      this.timeData = [];
      this.timeData = data;
      return data;
    },
  },
};
</script>

<style>
.highlight {
  background-color: #009688 !important;
  font-weight: bold;
  color: #fff !important;
}
</style>