<template>
  <v-card
    :height="height"
    width="180"
    tile
    class="flex-grow-0 flex-shrink-0"
  >
    <template v-for="(slot,i) in timeslots">
        <MSlot
            :key="`ss_${i}`"
            :height="50"
            :class="`pa-2 text-center`"
            :index="i"
            :tslot="slot"
            @onClickSlot="onClickSlot"
        />
    </template>
  </v-card>
</template>
<script>
import MSlot from "@/components/Membership/MSlot.vue";
export default {
    props: {
        increment: { type: Number, default: 60 },
        height: { type: Number, default: 50 },
        date: { type: String, default: null },
        timeslots: {type: Array, default: () => [],}
    },
    components: {
        MSlot,
    },
    mounted(){
        // console.log("this.date");
        // console.log(this.date);
        // console.log("this.timeslots");
        // console.log(this.timeslots);
    },
    methods: {
        onClickSlot(data) {
            data.date = this.date;
            this.$emit("openParticipantPopup", data);
        },
    }
};
</script>